import Swiper, {Pagination, Autoplay, Navigation, EffectCards} from "swiper";

export class Slider {
  constructor() {

    document.querySelectorAll('*[slider]').forEach(slider => {

      this.registerSlider(slider)

    })
  }

  registerSlider(slider) {

    let name = slider.getAttribute('data-slider')
    let per_view = slider.getAttribute('per-view')
    let per_view_sm = slider.getAttribute('per-view-sm')
    let nav = slider.getAttribute('navigation')

    const modules = [Pagination, Autoplay]
    let navigation = {}
    let pagination = {}

    let extra = {}

    if (nav === 'cards') {
      modules.push(EffectCards)
      extra.effect = 'cards'
      extra.grabCursor = true
      per_view_sm = 1
    }

    if (nav === 'arrow') {
      modules.shift()
      modules.push(Navigation)
      navigation = {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    } else {
      pagination = {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="swiper-pagination-bullet"></span>'
        }
      }
    }

    let config = {
      modules: modules,
      slidesPerView: per_view,
      loop: false,
      spaceBetween: 20,
      autoplay: {
        delay: 2500
      },
      navigation: navigation,
      pagination: pagination,
      breakpoints: {
        300: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: per_view_sm ?? 2,
        },
        1100: {
          slidesPerView: per_view
        }
      }
    }

    Object.assign(config, extra)

    console.log(config)

    const
      swiper = new Swiper('.' + name, config
      );

  }
}

export class Form {
  constructor() {
    this._form = document.querySelector('#forminator-module-32308')

    if (this._form) {

      document.addEventListener('forminator:form:submit:success', function (event) {

        console.log("Submitted " + event.formData)
        let postId = event.detail.formData.page_id;

        let data = {
          action: 'get_webinar_link',
          post_id: postId
        };

        jQuery.ajax({
          url: ajaxurl,
          type: 'POST',
          data: data,
          success: function (response) {
            alert('Champ ACF: ' + response);
          },
          error: function (error) {
            console.log('Erreur AJAX:', error);
          }
        });
      });

    }
  }
}

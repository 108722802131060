import '@css/main.scss';
import {Header} from './utils/Header';
import {Slider} from './utils/Slider';
import {Accordion} from './utils/Accordion'
import {Blog} from './utils/Blog';
import {Modal} from './utils/Modal';
import {Faq} from "@js/utils/Faq";
import {Form} from "@js/utils/Form";
//import {Animations} from "./utils/Animation";

window.onload = () => {
  new Form()
  new Header()
  new Slider()
  new Accordion()
  new Modal()
  new Faq()
}
